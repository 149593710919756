"use client";

import { removeItemInDB } from "@/components/features/cart-modal/actions";
import { ShopCartTransaction } from "@/lib/cart/cart-types";
import { useCartStore } from "@/state-management/stores/cart-store";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { memo, useCallback, useMemo, useTransition } from "react";
import { useFormState } from "react-dom";

const DeleteButton = memo(function DeleteButton({
    isPending,
    className = "flex h-[24px] w-[24px] items-center justify-center"
}: {
    isPending: boolean;
    className?: string;
}) {
    return (
        <button
            type="submit"
            aria-label="Remove cart item"
            className={className}
            disabled={isPending}
        >
            <XMarkIcon
                className={`mx-[1px] h-4 w-4 dark:text-black ${isPending ? "opacity-50" : ""}`}
            />
        </button>
    );
});

interface DeleteItemParams {
    cartTransactionID: number | null;
    productID: number;
    promotionID: number | null;
}

export function DeleteItemButton({ cartTrans }: { cartTrans: ShopCartTransaction }) {
    const [isPending, startTransition] = useTransition();
    const updateCartItem = useCartStore((state) => state.updateCartItem);
    const [message, formAction] = useFormState(removeItemInDB, null);

    const actionParams: DeleteItemParams = useMemo(
        () => ({
            cartTransactionID: cartTrans.cartTransactionID,
            productID: cartTrans.productID,
            promotionID: cartTrans.manualPromotionID
        }),
        [cartTrans.cartTransactionID, cartTrans.productID, cartTrans.manualPromotionID]
    );

    const boundFormAction = useCallback(async () => {
        startTransition(async () => {
            try {
                updateCartItem(actionParams.productID, "delete", actionParams.promotionID);
                await formAction(actionParams);
            } catch (error) {
                console.error("Failed to delete item:", error);
            }
        });
    }, [actionParams, formAction, updateCartItem]);

    return (
        <form action={boundFormAction}>
            <DeleteButton isPending={isPending} />
            {message && (
                <p aria-live="polite" className="sr-only" role="status">
                    {message}
                </p>
            )}
        </form>
    );
}
